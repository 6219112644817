var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "column": "",
      "justify-center": ""
    }
  }, [_c('div', {
    staticClass: "mb-40 mb-lg-60 text-center"
  }, [!_vm.$route.query._campaign ? _c('h3', {
    staticClass: "tit tit--md"
  }, [_vm._v("캠페인 등록")]) : _c('h3', {
    staticClass: "tit tit--md"
  }, [_vm._v("캠페인 상세")])]), _c('div', {
    staticClass: "form--primary"
  }, [_c('form-row', {
    attrs: {
      "tit": "제목"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": "",
      "placeholder": "소식지 제목을 입력하세요."
    },
    model: {
      value: _vm.support.subject,
      callback: function ($$v) {
        _vm.$set(_vm.support, "subject", $$v);
      },
      expression: "support.subject"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "기간"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "placeholder": "'YYYY-MM-DD' 형식을 지켜주세요",
      "hide-details": ""
    },
    model: {
      value: _vm.support.period,
      callback: function ($$v) {
        _vm.$set(_vm.support, "period", $$v);
      },
      expression: "support.period"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "첨부파일1"
    }
  }, [_c('v-file-input', {
    attrs: {
      "show-size": "",
      "placeholder": "PDF 파일을 첨부하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.support.files[0],
      callback: function ($$v) {
        _vm.$set(_vm.support.files, 0, $$v);
      },
      expression: "support.files[0]"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "첨부파일2"
    }
  }, [_c('v-file-input', {
    attrs: {
      "show-size": "",
      "placeholder": "PDF 파일을 첨부하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.support.files[1],
      callback: function ($$v) {
        _vm.$set(_vm.support.files, 1, $$v);
      },
      expression: "support.files[1]"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "첨부파일3"
    }
  }, [_c('v-file-input', {
    attrs: {
      "show-size": "",
      "placeholder": "PDF 파일을 첨부하세요.",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.support.files[2],
      callback: function ($$v) {
        _vm.$set(_vm.support.files, 2, $$v);
      },
      expression: "support.files[2]"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "내용"
    }
  }, [_c('v-textarea', {
    attrs: {
      "persistent-placeholder": "",
      "outlined": "",
      "full-width": "",
      "hide-details": "",
      "placeholder": "내용을 입력하세요."
    },
    model: {
      value: _vm.support.summary,
      callback: function ($$v) {
        _vm.$set(_vm.support, "summary", $$v);
      },
      expression: "support.summary"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "썸네일 이미지"
    }
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*",
      "outlined": "",
      "hide-details": "",
      "placeholder": "썸네일 이미지를 첨부하세요."
    },
    model: {
      value: _vm.support.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.support, "thumb", $$v);
      },
      expression: "support.thumb"
    }
  })], 1), _c('form-row', {
    attrs: {
      "tit": "이미지 미리보기"
    }
  }, [_vm.support.thumb ? _c('v-img', {
    staticClass: "border w-100",
    attrs: {
      "max-width": "282",
      "src": _vm.createObjectURL(_vm.support.thumb),
      "cover": "",
      "placeholder": "썸네일 이미지를 첨부하세요."
    }
  }) : _c('v-responsive', {
    staticClass: "grey-f5 border",
    attrs: {
      "max-width": "282",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-layout', {
    staticClass: "w-100 h-100 text-center",
    attrs: {
      "column": "",
      "justify-center": "",
      "align-center": ""
    }
  }, [_c('p', {
    staticClass: "page-text grey-d--text"
  }, [_vm._v(" 권장 비율 ")]), _c('h4', {
    staticClass: "tit tit--md grey-d--text"
  }, [_vm._v(" 1:1 ")])])], 1)], 1), _c('form-row', {
    attrs: {
      "tit": "상세내용"
    }
  }, [_c('naver-smarteditor', {
    model: {
      value: _vm.support.content,
      callback: function ($$v) {
        _vm.$set(_vm.support, "content", $$v);
      },
      expression: "support.content"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }