<template>
    <v-layout v-if="ready" column justify-center>
        <div class="mb-40 mb-lg-60 text-center">
            <h3 v-if="!$route.query._campaign" class="tit tit--md">캠페인 등록</h3>
            <h3 v-else class="tit tit--md">캠페인 상세</h3>
        </div>

        <div class="form--primary">
            <form-row tit="제목">
                <v-text-field v-model="support.subject" outlined persistent-placeholder hide-details placeholder="소식지 제목을 입력하세요."></v-text-field>
            </form-row>
            <form-row tit="기간">
                <v-text-field v-model="support.period" outlined placeholder="'YYYY-MM-DD' 형식을 지켜주세요" hide-details></v-text-field>
            </form-row>
            <form-row tit="첨부파일1">
                <v-file-input v-model="support.files[0]" show-size  placeholder="PDF 파일을 첨부하세요." outlined hide-details/>
            </form-row>
            <form-row tit="첨부파일2">
                <v-file-input v-model="support.files[1]" show-size  placeholder="PDF 파일을 첨부하세요." outlined hide-details/>
            </form-row>
            <form-row tit="첨부파일3">
                <v-file-input v-model="support.files[2]" show-size  placeholder="PDF 파일을 첨부하세요." outlined hide-details/>
            </form-row>
            <form-row tit="내용">
                <v-textarea v-model="support.summary" persistent-placeholder outlined full-width hide-details placeholder="내용을 입력하세요."></v-textarea>
            </form-row>
            <form-row tit="썸네일 이미지">
                <v-file-input v-model="support.thumb" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*" outlined hide-details placeholder="썸네일 이미지를 첨부하세요."></v-file-input>
            </form-row>
            <form-row tit="이미지 미리보기">
                <v-img v-if="support.thumb" max-width="282" :src="createObjectURL(support.thumb)" cover placeholder="썸네일 이미지를 첨부하세요." class="border w-100" />
                <v-responsive v-else max-width="282" :aspect-ratio="1 / 1" class="grey-f5 border">
                    <v-layout column justify-center align-center class="w-100 h-100 text-center">
                        <p class="page-text grey-d--text">
                            권장 비율
                        </p>
                        <h4 class="tit tit--md grey-d--text">
                            1:1
                        </h4>
                    </v-layout>
                </v-responsive>
            </form-row>
            <form-row tit="상세내용">
                <naver-smarteditor v-model="support.content"></naver-smarteditor>
            </form-row>
            
        </div>

        <div class="v-btn--group">
            <v-btn large @click="save" color="primary" class="min-w-140px min-w-lg-160px">저장</v-btn>
        </div>

        <!-- <v-responsive max-width="1024px" width="100%" height="100%" class="shop-support-view">
            <div v-if="!$route.query._campaign" class="headline text-start mt-8">게시글 등록</div>
            <div v-else class="headline text-start mt-8">게시글 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">제목</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="support.subject" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기간</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="support.period" label="기간" placeholder="ex) 2020-01-01 ~ 2020.12.31" hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">내용</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="12" class="py-0">
                            <v-textarea v-model="support.summary" persistent-placeholder outlined full-width></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="support.thumb" max-width="96" max-height="96" :src="createObjectURL(support.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="support.thumb" label="썸네일 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">상세내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="support.content"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive> -->
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";
import WorkImgsOverlay from "@/components/console/auction/work-imgs-overlay.vue";

import draggable from "vuedraggable";

import { mdiTagPlus } from "@mdi/js";

import FormRow from "@/components/client/sub/form-row.vue";

export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
        ImagesInput,
        draggable,
        WorkImgsOverlay,
        FormRow,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            support: {
                code: this.$route.query.code,

                subject: undefined,
                content: undefined,

                thumb: undefined,
                files: [],

                child: {
                    name: null,
                    sex: null,
                    birthDay: null,
                    family: null,
                    health: null,
                }
            },

            image_selected: null

        };
    },
    mounted() {
        this.init();
    },
    computed:{
        imgSrcs() {
            if(this.support.files == undefined) this.support.files = []
            return this.support.files.map((file) => URL.createObjectURL(file));
        },
    },
    methods: {
        async init() {
            console.log(this.$route.params);
            try {
                if (!!this.$route.params._campaign) {
                    let { support} = await api.v1.supports.donations.get({ _id: this.$route.params._campaign });
                    support.files = await Promise.all((support.files || []).map(async ({ url }) => await api.getResource(url, true)));
                    if (support.thumb) support.thumb = await api.getResource(support.thumb, true);
                    this.support = support;
                }

                // if (!!this.$route.params._campaign) {
                //     var { summary } = await api.v1.supports.donations.gets({
                //         headers: { skip: 0, limit: 1 },
                //         params: {
                //             code: this.$route.query.code,
                //         },
                //     });
                //     this.support.sequence = summary.totalCount + 1;
                // }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _files = [], files = [], thumb, ...support } = this.support;
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 삭제
                ////////////////////////////////////////////////////////////////
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.v1.supports.donations.files.delete({ _support: support?._id, _id })));

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ support }] = [support._id ? await api.v1.supports.donations.put(support) : await api.v1.supports.donations.post(support)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.v1.supports.donations.postThumb(support, thumb);
                if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.v1.supports.donations.files.post({ _support: support?._id, index }, file)));

                alert(this.support._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        setImage_selected(file) {
            const index = this.support.files.findIndex((img) => img == file);
            this.image_selected = index;
        },
        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
    watch: {
        "board.files.length"() {
            this.image_selected = this.support?.files?.length - 1;
        },
    },
};
</script>
