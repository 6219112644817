<template>
    <div>
        <div v-if="this.$route.query.code === 'koCampaign' || this.$route.query.code ==='enCampaign'">
            <v-tabs v-model="tab" hide-slider height="auto" class="v-tabs--primary tab--block">
                <v-tab @click="filter.code = 'koCampaign'">국내 캠페인</v-tab>
                <v-tab @click="filter.code = 'enCampaign'">국제 캠페인</v-tab>
            </v-tabs>
        </div>

        <div class="mb-12 mb-lg-20">
            <v-row class="row--x-small">
                <v-spacer></v-spacer>
                <v-col cols="12" md="auto">
                    <v-select class="v-input--small w-100 w-md-100px" placeholder="선택" v-model="filter.searchKey" :items="filterItems" persistent-placeholder dense outlined hide-details item-text="text" item-valuie="value"></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field class="v-input--small w-100 w-md-240px" v-model="filter.searchValue" :disabled="filter.searchKey == null" dense outlined hide-details placeholder="검색어를 입력하세요."></v-text-field>
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="search" dense><span class="white--text font-size-14">검색</span></v-btn>
                </v-col>
            </v-row>
        </div>

        <v-row class="board-contents">
            <v-col cols="6" md="4" v-for="support, idx in supports" :key="idx">
                <v-card outlined @click="show(support)" class="h-100">
                    <v-img :src="support.thumb" :aspect-ratio="1 / 1" class="w-100"/>
                    <div class="pa-16 pa-md-24">
                        <h5 class="font-size-16 font-size-lg-20 line-height-1 ellip">
                            {{support.subject}}
                        </h5>
                        <v-divider class="my-8 my-lg-16" />
                        <p class="page-text ellip--2 mb-4">
                            {{support.summary}}
                        </p>
                        <p class="page-text page-text--sm primary--text">
                            {{support.periodStartDate}} - <br class="d-sm-none">{{support.periodEndDate}} 
                        </p>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        
        <!-- <div>
            <v-row>
                <v-col v-for="support, idx in supports" :key="idx">
                    <div @click="show(support)">
                        <div>
                            <div>
                                <v-img :src="support.thumb" width="300px"></v-img>
                            </div>
                            <div class="w-100 pa-20 pa-md-30">
                                <h5 class="tit-txt font-weight-bold line-height-13 word-keep-all">
                                    {{support.subject}}
                                </h5>
                                <v-divider class="border-color-dark my-12 my-lg-20" />
                                <p class="page-text--sm line-height-13">
                                    {{support.summary}}
                                </p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div> -->
        <div class="v-pagination-wrap">     
            <v-pagination v-model="page" :length="pageCount" @input="search()"/>
            <!-- <a v-if="!!write" class="button button--primary" role="link" @click="$router.push(`?code=${filter.code}&&mode=input`)"><span>글쓰기</span></a> -->
        </div>

        <div v-if="!!write" class="mt-20 mt-lg-40">
            <v-row>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn outlined color="grey" @click="$router.push(`?code=${filter.code}&&mode=input`)">
                        글쓰기
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    components:{
    },
    props: {
        code: String
    },
    data() {
        return {
            filter:{
                code: this.$route.query.code || this.$props.code,
                searchKey: this.$route.query.searchKey || "",
                searchValue: this.$route.query.searchValue || "",
            },
            filterItems: [ "제목+내용", "제목", "내용" ],

            supports: [],
            page: 1,
            pageCount: 0,
			limit: 6,
            tab: null,
            write: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init(){
            if(this.$store.state.accessToken){
                let { user } = await api.v1.me.get();
                user.isApproved === true ? this.write = true : this.write = false;
            }
            if(this.$route.query.code){
                this.$route.query.code === 'koCampaign'? this.tab = 0: this.tab = 1
            }
            let { summary, supports } = await api.v1.supports.donations.gets({ 
                    headers: { 
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: { code: this.$route.query.code || this.$props.code }
                });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);   
            this.supports = supports;
        },
        async search(){
            if(this.$route.query.code){
                this.$route.query.code === 'koCampaign'? this.tab = 0: this.tab = 1
            }
            let { summary, supports } = await api.v1.supports.donations.gets({ 
                    headers: { 
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter
                });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);   
            this.supports = supports;
        },
        show(campaign) {
            this.$router.push({ path: `/campaign/${campaign._id}`});
        }
    },
    watch: {
        "filter.code"(){
            this.search()
        },
        "$route.query.code"(){
            this.init()
        }
    }

}
</script>

<style lang="scss" scoped>
</style>