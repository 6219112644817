import { render, staticRenderFns } from "./campaign-list.vue?vue&type=template&id=190a3e90&scoped=true&"
import script from "./campaign-list.vue?vue&type=script&lang=js&"
export * from "./campaign-list.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "190a3e90",
  null
  
)

export default component.exports