<template>
    <div>
        <table class="board-view" style="border-bottom: 0 !important;">
            <tr>
                <th>{{subject}}</th>
            </tr>
            <tr>
                <td>
                    <div v-for="file, idx in files" :key="idx">
                        첨부파일: <span @click="download(`${file.url}`, `${file.fileName}`)" class="cursor-pointer">{{file.fileName}}</span>
                    </div> 
                </td>
            </tr>
            <tr>
                <td class="content_box">
                    <div v-html="content"></div> 
                </td>
            </tr>
        </table>

        <v-card outlined class="mt-40 mt-lg-60">
            <div class="pa-20 pa-md-30 pa-lg-40">
                <v-layout column align-center class="text-center word-keep-all">                             
                    <a href="tel:0220572296" target="_blank"><i class="icon icon-tel mb-8" /></a>
                    <p class="page-text page-text--lg secondary--text font-weight-bold line-height-1 mb-16 mb-lg-24"><a href="tel:0220572296">후원문의 : 02-2057-2296</a></p>
                    <v-row class="row--x-small">
                        <v-col cols="12" xl="auto">
                            <p class="page-text page-text--lg grey-1--text line-height-15">후원 계좌 : 017037-04-003957 KB국민은행</p>
                        </v-col>
                        <v-col cols="auto" class="d-none d-xl-block">
                            <v-divider vertical />
                        </v-col>
                        <v-col cols="12" xl="auto">
                            <p class="page-text page-text--lg grey-1--text line-height-15">
                                예금주 : 사회복지법인 위드 캔 복지재단 <br class="d-sm-none">
                                <span class="font-size-14 font-size-lg-16" style="color: #C62828 !important;">(계좌송금시 예금주명을 반드시 확인해 주세요.)</span>
                            </p>
                        </v-col>
                    </v-row>
                    <p class="page-text line-height-17 mt-8">                                
                        온라인(정기ㆍ일시후원), 무통장입금, 회지 등을 통해 모금된 후원금 평가 척도에 맞춰 수혜자 가정에게 지급합니다. <br>
                        또한 지급하고 남은 후원금은 어려움을 겪고 있는 다른 대상자들과 캠페인 활동을 위해 사용되고 있습니다.
                    </p>
                </v-layout>
            </div>
        </v-card>
        
        <div class="v-btn--group v-btn--group--sm">
            <v-btn large color="primary" class="min-w-140px min-w-lg-160px" @click="$router.push(`/support/regular?_campaign=${_support}`)">정기후원</v-btn>
            <v-btn large color="primary" class="min-w-140px min-w-lg-160px" @click="$router.push(`/support/temporary?_campaign=${_support}`)">일시후원</v-btn>
        </div>
        
        <div class="mt-40 mt-lg-60">
            <v-row justify="end" class="row--x-small">
                <v-col cols="auto">
                    <v-btn @click="$router.go(-1)" outlined color="grey-9">목록보기</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-if="!!write" @click="$router.push(`?mode=input`)" outlined color="grey-9">수정하기</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-if="!!write" @click="deleteBoard" outlined color="grey-9">삭제하기</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
    <!-- <v-card>
        <v-card-text>
            <v-card-title>{{subject}}</v-card-title>
            <div v-html="content"></div> 
        </v-card-text>
    </v-card> -->
</template>

<script>
import api from "@/api";
import download from "downloadjs";
export default {
    computed:{
    },
    props:{

    },
    data() {
        return {
            subject: null,
            content: null,
            _support: null,
            write: false,
            support: null,
            files: null,
            code: null
        }
    },
    mounted() {
        this.init()
    },
    methods:{
        async init() {
            let { support } = await api.v1.supports.donations.get({ _id: this.$route.params._campaign });
            this.support = support;
            this._support = support._id;
            this.subject = support.subject;
            this.content = support.content;
            this.files = support.files;
            this.code = support.code;

            if(this.$store.state.accessToken){
                let { user } = await api.v1.me.get();
                user.isApproved === true && support.writer._id === user._id ? this.write = true : this.write = false;
            }
        },
        async deleteBoard(){
            if(confirm("삭제하시겠습니까?")) {
                await api.v1.supports.donations.delete(this.support);
                this.$router.go(-1);
            }
        },
        download(path, rename){
            api.getResource(path).then(file => download(file, rename));
        }
    }
}
</script>

<style>

</style>