var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table', {
    staticClass: "board-view",
    staticStyle: {
      "border-bottom": "0 !important"
    }
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.subject))])]), _c('tr', [_c('td', _vm._l(_vm.files, function (file, idx) {
    return _c('div', {
      key: idx
    }, [_vm._v(" 첨부파일: "), _c('span', {
      staticClass: "cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.download(`${file.url}`, `${file.fileName}`);
        }
      }
    }, [_vm._v(_vm._s(file.fileName))])]);
  }), 0)]), _c('tr', [_c('td', {
    staticClass: "content_box"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })])])]), _c('v-card', {
    staticClass: "mt-40 mt-lg-60",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 pa-lg-40"
  }, [_c('v-layout', {
    staticClass: "text-center word-keep-all",
    attrs: {
      "column": "",
      "align-center": ""
    }
  }, [_c('a', {
    attrs: {
      "href": "tel:0220572296",
      "target": "_blank"
    }
  }, [_c('i', {
    staticClass: "icon icon-tel mb-8"
  })]), _c('p', {
    staticClass: "page-text page-text--lg secondary--text font-weight-bold line-height-1 mb-16 mb-lg-24"
  }, [_c('a', {
    attrs: {
      "href": "tel:0220572296"
    }
  }, [_vm._v("후원문의 : 02-2057-2296")])]), _c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey-1--text line-height-15"
  }, [_vm._v("후원 계좌 : 017037-04-003957 KB국민은행")])]), _c('v-col', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey-1--text line-height-15"
  }, [_vm._v(" 예금주 : 사회복지법인 위드 캔 복지재단 "), _c('br', {
    staticClass: "d-sm-none"
  }), _c('span', {
    staticClass: "font-size-14 font-size-lg-16",
    staticStyle: {
      "color": "#C62828 !important"
    }
  }, [_vm._v("(계좌송금시 예금주명을 반드시 확인해 주세요.)")])])])], 1), _c('p', {
    staticClass: "page-text line-height-17 mt-8"
  }, [_vm._v(" 온라인(정기ㆍ일시후원), 무통장입금, 회지 등을 통해 모금된 후원금 평가 척도에 맞춰 수혜자 가정에게 지급합니다. "), _c('br'), _vm._v(" 또한 지급하고 남은 후원금은 어려움을 겪고 있는 다른 대상자들과 캠페인 활동을 위해 사용되고 있습니다. ")])], 1)], 1)]), _c('div', {
    staticClass: "v-btn--group v-btn--group--sm"
  }, [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`/support/regular?_campaign=${_vm._support}`);
      }
    }
  }, [_vm._v("정기후원")]), _c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`/support/temporary?_campaign=${_vm._support}`);
      }
    }
  }, [_vm._v("일시후원")])], 1), _c('div', {
    staticClass: "mt-40 mt-lg-60"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey-9"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("목록보기")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [!!_vm.write ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey-9"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`?mode=input`);
      }
    }
  }, [_vm._v("수정하기")]) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [!!_vm.write ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey-9"
    },
    on: {
      "click": _vm.deleteBoard
    }
  }, [_vm._v("삭제하기")]) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }